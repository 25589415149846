import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { ISearchProductDocument } from '@wix/client-search-sdk';

export const addProductToCart = async (
  { id, relativeUrl }: ISearchProductDocument,
  wixCodeApi: IWixAPI,
): Promise<void> => {
  const {
    stores: storesApi,
    location: locationApi,
    window: windowApi,
  } = wixCodeApi;

  // NOTE: We cannot add directly to cart when product has options, custom text fields or
  // subscriptions. Workaround is to redirect to product page when add to cart request fails.
  try {
    await storesApi?.cart.addProducts([{ productId: id, quantity: 1 }]);
  } catch {
    locationApi.to?.(relativeUrl);
    return;
  }

  if (windowApi.formFactor === 'Mobile') {
    locationApi.to?.('/cart-page');
  } else {
    storesApi?.cart.showMiniCart();
  }
};
